@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');



@font-face {
  font-family: "Open Sans";
  src: local("Open Sans"),
       url("./fonts/OpenSans-VariableFont_wdth\,wght.ttf") format("truetype");
  font-display: swap; 
}

@font-face {
  font-family: "Fira Code";
  src: local("Fira Code"),
       url("./fonts/FiraCode-VariableFont_wght.ttf") format("truetype");
  font-weight: 100 900; 
  font-display: swap;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  --color: rgba(20, 25, 34, 0.3);
  background-color: hsl(240, 20%, 8%);
  background-image: linear-gradient(0deg, transparent 24%, var(--color) 25%, var(--color) 26%, transparent 27%,transparent 74%, var(--color) 75%, var(--color) 76%, transparent 77%,transparent),
      linear-gradient(90deg, transparent 24%, var(--color) 25%, var(--color) 26%, transparent 27%,transparent 74%, var(--color) 75%, var(--color) 76%, transparent 77%,transparent);
  background-size: 55px 55px;
}
  


svg {
  display: flex;
  align-items: center;
}

.text-shadow {
  text-shadow: 2px 2px 4px rgba(128, 128, 128, 0.6); 
}

/* Анимация звезд в разделе отзывы */

@keyframes fall {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(100vh);
    opacity: 0;
  }
}

.animate-fall {
  animation: fall linear infinite;
}


/* Маска для видео */
.mask-video {
  mask-image: linear-gradient(to bottom, black 70%, transparent 100%);
  -webkit-mask-image: linear-gradient(to bottom, black 70%, transparent 100%);
  z-index: 0;
}

.no-download {
  pointer-events: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


